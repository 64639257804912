*{
  padding:  0;
  margin: 0;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.AddAndDelete .main {
    border: 1px solid #E9E9E9;
    border-radius: 4px;
    padding: 10px;
  }
  .AddAndDelete .tag {
    position: relative;
    margin: 3px;
    font-size: 12px;
    /* border: 1px solid #3d3d3d; */
    cursor: grab;
    border-radius: 25px;
    padding: 2px 20px 2px 10px;
    line-height: 30px;
    color: #333;
    font-weight: bold;
    background: #cacccf;
  }
  .AddAndDelete .tag:active {
    cursor: grabbing;
  }
  .AddAndDelete .delete {
    position: absolute;
    top: 1px;
    right: 2px;
    width: 16px;
    height: 10px;
    cursor: pointer;
    /* user-drag: none;  */
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  .AddAndDelete .inputs {
    margin-top: 10px;
  }
  .AddAndDelete .inputs input {
    position: relative;
    top: -1px;
    height: 23px;
    border: none;
    border-bottom: 1px solid #cacccf;
  }
  .AddAndDelete .inputs input:focus {
    outline: none;
  }
  .AddAndDelete .inputs button {
    padding: 1px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 5px;
}
