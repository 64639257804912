@import '~bootstrap/scss/bootstrap.scss';

.modal {
  z-index: 1250;
  &.show {
    background-color: #3333339c;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
.react-tagsinput {
  overflow: hidden;
  // margin: 10px 0 0 0;
}

.react-tagsinput--focused {
}

.react-tagsinput-tag {
  background-color: rgb(224, 224, 224);
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 4px 8px;
  cursor: default;
  border-radius: 16px;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
  margin-left: 8px;
}

.react-tagsinput-tag a::before {
  content: ' ×';
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 0;
  width: 120px;
}

.createNewUser,
.createUpdateCategory {
  padding-bottom: 10px;
}
