.message-list {
    padding: 12px 16px;
    height: 550px;
    overflow-y: auto;
    overflow-x: hidden;

    .swap-message-container {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: -ms-flex;
        display: flex;
        margin: 8px;

        .swap-message-username-row {
            margin-bottom: 6px;
        }

        .swap-message--avatar {
            -webkit-align-self: flex-end;
            -ms-align-self: flex-end;
            align-self: flex-end;
            display: block;
            margin-right: 16px;
            height: 40px;
            width: 40px;

            img {
                border-radius: 50%;
                border: 1px solid #efeff4;
                height: 40px;
                width: 40px;
                object-fit: cover;

                &.mine {
                    margin: -2px 0 0 16px;
                }
            }
        }

        &.theirs {
            max-width: 70%;
            margin-right: auto;

            &.no-avatar {
                margin-left: 64px;
            }
        }

        &.mine {
            max-width: 70%;
            margin-left: auto;
            -webkit-justify-content: flex-end;
            -ms-justify-content: flex-end;
            justify-content: flex-end;
        }

        .swap-message {
            padding: 8px 16px;
            text-align: left;
            border-radius: 8px;
            font-size: 0.875rem;
            font-family: Open Sans, sans-serif;
            white-space: pre-line;
            margin: 0;

            &.swap-message--theirs {
                color: #4a4a4a;
                background-color: #b0b2b4;
            }

            &.swap-message--mine {
                color: #fff;
                //background-color: #02c874;
                background-color: var(--primary);
            }

            &.swap-message--event {
                font-style: italic;
            }
        }

        .message-time {
            font-size: 0.625rem;
            font-weight: 600;
            color: #9b9b9b;

            &.message-time--mine {
                text-align: right;
            }
        }
    }

    .swap-messages--time-row {
        text-align: center;
        font-size: 0.625rem;
        font-weight: 600;
        color: #9b9b9b;
    }
}

.message-input {
    //background: #22242e;
    border-radius: 6px;
    padding-left: 10px;
    padding-right: 10px;
    resize: vertical;
    overflow: hidden;

    //color: #fff;
    input {
        //color: #fff;
        font-weight: bold;
    }
}


.note-list {
    padding: 10px 10px;
    height: 250px; //550px;
    overflow-y: auto;
    overflow-x: hidden;

    .swap-note-container {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: -ms-flex;
        display: flex;
        margin: 8px;

        .swap-note-username-row {
            margin-bottom: 6px;
        }

        .swap-note--avatar {
            -webkit-align-self: flex-end;
            -ms-align-self: flex-end;
            align-self: flex-end;
            display: block;
            margin-right: 16px;
            height: 40px;
            width: 40px;

            img {
                border-radius: 50%;
                border: 1px solid #efeff4;
                height: 40px;
                width: 40px;
                object-fit: cover;

                &.mine {
                    margin: -2px 0 0 16px;
                }
            }
        }

        &.theirs {
            max-width: 70%;
            margin-right: auto;

            &.no-avatar {
                margin-left: 64px;
            }
        }

        &.mine {
            max-width: 70%;
            margin-left: auto;
            -webkit-justify-content: flex-end;
            -ms-justify-content: flex-end;
            justify-content: flex-end;
        }

        .swap-note {
            padding: 8px 16px;
            text-align: left;
            border-radius: 8px;
            font-size: 0.900rem;
            font-weight: 200;
            font-family: Open Sans, sans-serif;
            white-space: pre-line;
            margin: 0;

            &.swap-note--theirs {
                color: #4a4a4a;
                background-color: #b0b2b4;
            }

            &.swap-note--mine {
                color: #fff;
                // background-color: #507696;
                background-color: var(--primary);
            }

            &.swap-note--event {
                font-style: italic;
            }
        }

        .note-time {
            font-size: 0.625rem;
            font-weight: 600;
            color: #9b9b9b;

            &.note-time--mine {
                text-align: right;
            }
        }
    }

    .swap-notes--time-row {
        text-align: center;
        font-size: 0.625rem;
        font-weight: 600;
        color: #ebc0c0;
    }
}

.note-input {
    //background: #22242e;
    border-radius: 6px;
    padding-left: 10px;
    padding-right: 10px;
    resize: vertical;
    overflow: hidden;

    //color: #fff;
    input {
        //color: #fff;
        font-weight: bold;
    }
}

.note-card {
    height: 485px;
    width: 534px;
}

.note-footer-outerDiv {
    height: 132px;
    width: 500px;
}